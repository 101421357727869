<template>
  <div>
    <div>
      <v-row v-if="isMobileWidth === 1" class="ma-4 mt-0 pt-0">
        <v-col cols="12">
          <list-influencer></list-influencer>
        </v-col>
      </v-row>
      <v-row v-else class="">
        <v-col cols="12">
          <list-influencer></list-influencer>
        </v-col>
      </v-row>
      <!-- <v-row class="ma-4 mt-3 pt-0 mb-10 pb-5">
        <v-col cols="12">
          <video-home></video-home>
        </v-col>
      </v-row> -->
    </div>
  </div>
</template>

<script>
import ListInfluencer from './components/ListInfluencer.vue'
import axios from 'axios'

export default {
  name: 'influencer',
  metaInfo: {
    title: 'List Influencer'
  },
  components: {
    ListInfluencer
  },
  setup() {
    return {
      isLoading:false,
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    dateFormat(value){
      return value.toString().slice(0, 10)
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
